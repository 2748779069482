<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Announcement</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <form @submit.prevent="postAnnouncement" class="row p-2">
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Announcement Title</label>
                                    <input v-model="form.title" type="text" required class="form-control text-grey-900 font-xsss fw-600">
                                </div>
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Status Comment</label>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" @change="checkSwitch" v-model="form.status" class="custom-control-input dark-mode-switch" id="darkmodeswitch">
                                        <label class="custom-control-label bg-current" for="darkmodeswitch"></label>
                                    </div>
                                </div>
                                <div class="col-xl-12 form-group mb-3">
                                    <label for="">Message</label>
                                    <vue-editor v-model="form.message"/>
                                </div>
                                <div class="col-xl-12 form-group text-right my-4">
                                    <router-link :to="{name: 'AnnouncementCoursesAdmin',params: {idCourse: paramsId}}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                    <input type="Submit" class="btn btn-warning" value="Submit">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
// import CustomCkEditor from '../../../../../components/helpers/CustomCkEditor.vue'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// import Image from '@ckeditor/ckeditor5-image/src/image';
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
// import ImageResizeButtons from '@ckeditor/ckeditor5-image/src/imageresize/imageresizebuttons';
// import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
// import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
// import Link from '@ckeditor/ckeditor5-link/src/link';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

export default {
    name: "AnouncementsCreate",
    components: {
        // CustomCkEditor
    },
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            form: {
                title: '',
                status: true,
                message: ''
            },
            validationErrors: '',
            // editor: ClassicEditor,
            // editorConfig: {
            //     plugins: [
            //         Base64UploadAdapter,
            //         Essentials,
            //         Autoformat,
            //         Bold,
            //         Italic,
            //         Heading,
            //         Image,
            //         ImageCaption,
            //         ImageResizeEditing,
            //         ImageResizeButtons,
            //         ImageResizeHandles,
            //         ImageStyle,
            //         ImageToolbar,
            //         ImageUpload ,
            //         Link,
            //         Paragraph,
            //     ],

            //     toolbar: {
            //         items: [
            //             'heading',
            //             '|',
            //             'bold',
            //             'italic',
            //             'link',
            //             'uploadImage',
            //             'undo',
            //             'redo'
            //         ]
            //     },
            //     image: {
            //         resizeOptions: [
            //             {
            //                 name: 'resizeImage:original',
            //                 value: null,
            //                 icon: 'original'
            //             },
            //             {
            //                 name: 'resizeImage:50',
            //                 value: '50',
            //                 icon: 'medium'
            //             },
            //             {
            //                 name: 'resizeImage:75',
            //                 value: '75',
            //                 icon: 'large'
            //             }
            //         ],
            //         toolbar: [
            //             'imageStyle:full',
            //             'imageStyle:side',
            //             '|',
            //             'resizeImage',
            //         ]
            //     },
            //     // This value must be kept in sync with the language defined in webpack.config.js.
            //     language: 'en'
            // }
        }
    },
    methods:{
        async postAnnouncement() {
            var data = {
                title : this.form.title,
                message : this.form.message,
                comment_status : this.form.status,
                course_id : this.paramsId
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/announcement`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Success Create New Announcement !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'AnnouncementCoursesAdmin' })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    // this.$toastr.error(this.validationErrors[0][0], 'failed Create New Course !');
                    this.$swal({
                        toast: true,
                        title: 'Failed Create New Announcement !',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        checkSwitch() {
            this.form.status !== this.form.status
        },
    }
}
</script>
